:root {
  // sidebar menu
  --sidenav-width: 256px;
  --sidenav-bg: #001e2e;
  --sidenav-color: #ffffff;
  --sidenav-hover-bg: #002e42;
  --sidenav-active-bg: #004c6d;

  // toolbar
  --toolbar-width: calc(100% - var(--sidenav-width));
  --toolbar-height: 80px;
  --toolbar-bg: var(--surface-hover);

  // main theme colors
  --theme-primary: #004c6d;
  --theme-warning: #ba1a1a;
  --theme-accent: #13b5fb;

  // error state
  --error-bg: #ba1a1a;
  --error-color: #ffffff;
  --error-inverse-bg: var(--surface-enabled);
  --error-inverse-color: #ba1a1a;
  --error-container-bg: #ffdad6;
  --error-container-color: #212121;
  --error-container-inverse-bg: #212121;
  --error-container-inverse-color: #ffdad6;

  // progress state
  --progress-bg: #ffc107;
  --progress-color: #ffffff;
  --progress-inverse-bg: var(--surface-enabled);
  --progress-inverse-color: #ffc107;
  --progress-container-bg: #ffecb3;
  --progress-container-color: #212121;
  --progress-container-inverse-bg: #212121;
  --progress-container-inverse-color: #ffecb3;

  // success state
  --success-bg: #4caf50;
  --success-color: #ffffff;
  --success-inverse-bg: var(--surface-enabled);
  --success-inverse-color: #4caf50;
  --success-container-bg: #c8e6c9;
  --success-container-color: #212121;
  --success-container-inverse-bg: #212121;
  --success-container-inverse-color: #c8e6c9;

  // new state
  --new-bg: #004c6d;
  --new-color: #ffffff;
  --new-inverse-bg: var(--surface-enabled);
  --new-inverse-color: #004c6d;
  --new-container-bg: #b1e0e7;
  --new-container-color: #212121;
  --new-container-inverse-bg: #212121;
  --new-container-inverse-color: #b1e0e7;

  // waiting/disabled state
  --disabled-bg: #424242;
  --disabled-color: #ffffff;
  --disabled-inverse-bg: var(--surface-enabled);
  --disabled-inverse-color: #424242;
  --disabled-container-bg: #e0e0e0;
  --disabled-container-color: #212121;
  --disabled-container-inverse-bg: #212121;
  --disabled-container-inverse-color: #e0e0e0;

  // surface container state
  --surface-enabled: #ffffff;
  --surface-hover: #fafafa;
  --surface-focus: #eeeeee;

  // json preview syntax higlight
  --json-string: #FF6B6B;
  --json-number: #009688;
  --json-boolean: #B938A4;
  --json-date: #05668D;
  --json-array: #999;
  --json-object: #999;
  --json-function: #999;
  --json-null: #fff;
  --json-undefined: #fff;

  // text colors
  --dark-primary-text: #000000ff;
  --light-primary-text: #ffffff;
}
