@use '@angular/material' as mat;
@use './hub-theme' as hub;
@import './variables';
@import './filters';

html,
body {
  @apply h-full;
}

.page-head {
  @apply px-6 flex justify-between;

  h1 {
    @apply mb-0 font-medium;
  }
}

.page-content {
  @apply px-6 pt-6 pb-14;
}

.section-head {
  @apply px-6 pt-4 flex justify-between;

  h2 {
    @apply mb-0 text-2xl font-medium;
  }
}

.section-content {
  @apply px-6 pt-6 pb-8;
}

#sidenav {
  @apply w-[var(--sidenav-width)] bg-[color:var(--sidenav-bg)] border-none;

  .logo {
    @apply mb-12 py-6 flex justify-center items-center bg-[color:var(--sidenav-bg)];

    img {
      @apply h-16;
    }
  }

  .mat-list-item {
    @apply text-sm text-[color:var(--sidenav-color)];

    &:hover {
      @apply bg-[color:var(--sidenav-hover-bg)];
    }

    &.active {
      @apply bg-[color:var(--sidenav-active-bg)];
    }
  }
}

a,
.link {
  @apply cursor-pointer text-[color:var(--theme-primary)];
}

.color-picker {
  .mat-menu-content:not(:empty) {
    @apply p-0;
  }

  app-color-picker {
    @apply flex;
  }
}

.bell-menu {
  &.mat-menu-panel {
    @apply max-w-[350px] w-[350px];
  }

  .mat-menu-item {
    @apply h-[60px] pt-2 leading-[inherit];
  }

  .blue-dot {
    @apply w-[7px] h-[7px] block absolute right-4 top-[45%] bg-[color:var(--theme-accent)] rounded-[50%] z-[9999];
  }
}

.justify-between .mat-option-text {
  @apply flex;
  justify-content: space-between;
}

.mat-form-field {
  @apply w-full;
}

.mat-card-title {
  @apply text-xl;
}

.mat-mdc-table {
  @apply cursor-default;

  .mat-mdc-row:hover {
    @apply bg-[color:var(--surface-hover)];
  }

  .mat-icon:not(.mat-primary):not(.success):not(.failure) {
    @apply text-black opacity-50;
  }

  tr.detail-row {
    @apply h-0;

    & td {
      @apply p-0 #{!important};
    }
  }

  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    @apply py-1.5 pr-1.5 pl-2.5 #{!important};
  }
}

.mat-mdc-paginator {
  @apply border-t;

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    @apply bg-transparent;
  }
}

.cdk-overlay-container .cdk-overlay-pane .not-overlay-select {
  @apply mt-8;
}

.mat-icon {
  &.success {
    @apply text-[color:var(--success-bg)];
  }

  &.failure {
    @apply text-[color:var(--error-bg)];
  }
}

.mat-datepicker-toggle-default-icon {
  @apply inline;
}
