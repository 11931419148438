.search-and-filter {
  &-section {
    @apply flex flex-wrap justify-between gap-2;
  }
}

.search {
  &-container {
    @apply pb-6 flex items-center flex-wrap gap-4;
  }

  &-item {
    @apply w-[360px] px-3.5 p-2 flex items-center gap-2.5 border rounded;

    .mat-input-element {
      @apply w-full h-[37px];
    }
  }
}

.filter {
  &-container {
    @apply pb-6 flex items-center flex-wrap gap-4;
  }

  &-buttons-group {
    @apply h-[50px] flex gap-1;

    .mat-mdc-button,
    .mat-mdc-unelevated-button {
      @apply h-auto;
    }
  }

  &-item {
    @apply w-[260px];

    &_sm {
      @apply w-[120px];
    }

    ::ng-deep .mat-form-field-wrapper {
      @apply p-0;
    }

    ::ng-deep .mat-form-field-suffix {
      @apply flex items-center;
    }

    .hide-clear-btn {
      @apply opacity-0 cursor-auto pointer-events-none;
    }
  }
}

:host ::ng-deep .mat-form-field-infix {
  @apply inline-flex items-center;
}
